import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { appInfo, fetchEventDetails } from "../../network/service";
import { updateWatchlistData } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { ReactComponent as PremiumIcon } from "../../assets/Icons/premium.svg";
import { ReactComponent as AddListIcon } from "../../assets/Icons/addToWatchlist.svg";
import {ReactComponent as PlayTrailor} from "../../assets/Icons/playTrailor.svg";
import { ReactComponent as RemoveListIcon } from "../../assets/Icons/removeFromWatchlist.svg";
import { imageUrlCheck } from "../../utils/utils";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
// import { getSelectedChannel } from "../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";

const ShowCard = ({ data, metaData, imageUrl, type}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [imagURL,setImageURL] = useState(thumbnailOrientation==="PORTRAIT"?require("../../assets/Images/loading-portrait.gif"):require("../../assets/Images/loading-landscape.gif"))
  const [isImageLoad,setIsImageLoad] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    afterImageLoad()
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);


  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        fetchWatchlist();
        if (flag === 1) {
          // toast.success("Added to mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("added");
        } else {
          // toast.success("Removed from mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addToWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login");
    }
  };
  const removeFromWatchlist = (showId) => {
    updateWatchlist(showId, 0);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub?.some((user) =>
          videoSub?.some((video) => user.sub_id === video.subscription_id)
        );
        return hasMatch;
  };

  const showClickHandler = () => {  
  if(data?.video_id != null)
  {
      navigate(`/videos/${data?.video_vanity_url || data?.vanity_url}`, {state: { videoDetails:data }, })
  }
  else{
      { if(type==="LIVE_EVENTS"||type==="LIVE_EVENTS" ){
        if(user){
          linearEventHandler()
        }else{
        navigate("/login");
        }
      }else if(data?.type === "PODCAST"){
        let podcastData = {
          isOpen:true,
          data
        }
        dispatch(
          getPodcastModal({
            podcastModal:podcastData
          })
        )
      }else if(data?.type === "NEWS"){
        navigate("/news")
      }
      else if(data?.type === "CONTINUE_WATCHING"){
        navigate(`/videos/${data?.video_vanity_url}`, {
          state: { videoDetails:data },
        })
      }
      else if(data?.type === "UPCOMING_EVENT" || type === "UPCOMING_EVENTS"){
        navigate(`/event/${data?.vanity_url?data?.vanity_url:data?.event_id}`)

      }
      else if(data?.type === "VIDEO"){
        navigate(`/videos/${data?.vanity_url}`, {
          state: { videoDetails:data },
        })
      }
      else if(data?.type === "ENDED_EVENT" ||type === "ENDED_EVENTS"){
        navigate(`/movies/${data?.vanity_url?data?.vanity_url:data?.event_id}`, {
          state: { showId: data?.show_id,type:data?.type ,vanityUrl:data?.vanity_url},
        })
      }
      else if(data?.type === "SHOW"){
        navigate(`/movies/${data?.vanity_url?data?.vanity_url:data?.event_id}`, {
          state: { showId: data?.show_id,type:data?.type ,vanityUrl:data?.vanity_url},
        })
      }
      else if(data?.type === "FASTCHANNEL"){
        if (projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true") {
          // dispatch(
          //   getSelectedChannel({
          //     selectedChannel: { channel_id: data?.channel_id }
          //   })
          // )
          dispatch(
            getSelectedSchedule({
              selectedSchedule: { channel_id: data?.channel_id }
            })
          )
          navigate("/live-channels");
        } else {
          navigate("/player")
        }
      }
      else if(data?.type === "EVENT"){
        navigate(`/event/${data?.vanity_url?data?.vanity_url:data?.event_id}`)
      }
      else if( type=== "Episode" ){
        navigate(`/videos/${data?.vanity_url}`, {
          state: { videoDetails:data },
        })
      }else if(type!=='episodes'||type!=="UPCOMING_EVENTS"){
        navigate(`/movies/${data?.vanity_url}`, {
          state: { showId: data?.show_id,type:data?.type },
        })
      }
      }
     }
  }

  const getEventDetails = async () => {
    const response = await fetchEventDetails(appInfo,data?.event_id);
    if(response?.status === 200) {
      return response?.data?.data
    }
  }

  const linearEventHandler = async () => {
    let linearEventDetails = await getEventDetails()
    if(linearEventDetails?.free_event === true){
      navigate("/live", {state:{eventId:linearEventDetails?.event_id}})
    }else if(
      linearEventDetails?.payper_flag ===1 || 
      linearEventDetails?.premium_flag ===1 || 
      linearEventDetails?.rental_flag ===1)
      {
        let isSubscribedUser = userSubscriptionData?.data?.length > 0 ? subscriptionCheck(userSubscriptionData?.data,linearEventDetails?.subscriptions) :false;
        if(isSubscribedUser){
            navigate("/live", {state:{eventId:linearEventDetails?.event_id}})
        }else{
          localStorage.setItem("eventId",linearEventDetails?.event_id)
          navigate("/subscription" , {state:{eventId:linearEventDetails?.event_id}})
        }
      }

  }

  const replaceImage=(error)=>{
    error.target.src= thumbnailOrientation==='PORTRAIT'? projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE_PORTRAIT : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  const afterImageLoad=(e)=>{
    setIsImageLoad(true)
    let image;
    if(imageUrlCheck(thumbnailOrientation==='PORTRAIT'?data?.logo || data?.thumbnail:data?.logo_thumb || data?.thumbnail_350_200)===true){
      if(thumbnailOrientation === "PORTRAIT"){
        image = data?.logo || data?.thumbnail
      }
      else{
        image = data?.logo_thumb || data?.thumbnail_350_200
      }
    }else {
      if(thumbnailOrientation === "PORTRAIT"){
        image = process.env.REACT_APP_IMAGE_URL + (data?.logo || data?.thumbnail)
      }else{
        image = process.env.REACT_APP_IMAGE_URL + (data?.logo_thumb || data?.thumbnail_350_200)
      }
    }
    setImageURL(image)
  }
 

  return (
    <div
      className="showCardContainer"
      title={data?.show_name || data?.video_title}
    >
      {/* <ToastContainer /> */}
      {/* <div className="premiumStatus">
        {data?.is_free_video === false ? (
          <div className="premium">
            <PremiumIcon />
          </div>
        ) : data?.is_free_video === true ? (
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
        ) : null}
      </div> */}
      <div className="premiumStatus">
        { data?.is_free_video === true ? (
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
        )
         : data?.is_locked === true ?(
          <div className="lock">
            <img
            src={require("../../assets/Images/lock.png")}
            alt="LockIcon"
            />
          </div>
        )
        :null
        }
      </div>

      <div className="imageContainer">
      {/* {imageUrlCheck(thumbnailOrientation==='PORTRAIT'?data?.logo || data?.thumbnail:data?.logo_thumb || data?.thumbnail_350_200) ===false ? (
          <img
            src={thumbnailOrientation==='PORTRAIT'?`${process.env.REACT_APP_IMAGE_URL}${
              data?.logo || data?.thumbnail
            }`:`${process.env.REACT_APP_IMAGE_URL}${
              data?.logo_thumb || data?.thumbnail_350_200
            }`}
            alt="ShowImage"
          />
        ) : (
          <img src={thumbnailOrientation==='PORTRAIT'?
          `${data?.logo || data?.thumbnail}`
          :
          `${data?.logo_thumb || data?.thumbnail_350_200}`
        } alt="ShowImage" />
          
        )} */}
         <img
            className={!isImageLoad?(thumbnailOrientation==="PORTRAIT"?"portrait":"landscape"):"showImage"}
              src={
                imagURL
              }
              onError={(e)=>replaceImage(e)}
              onLoad={(e)=>afterImageLoad(e)} 
              alt="ShowImage"
            />
        {type === "CONTINUE_WATCHING" && (
          <div className="continueWatchingBar">
            <div
              className="line"
              style={{
                width:
                  data?.watched_percentage <= 1
                    ? 1 + "%"
                    : data?.watched_percentage + "%",
              }}
            ></div>
          </div>
        )}

        <div className={type==="LIVE_GUIDE" ? "extraActions hide" : "extraActions show"}>
          <div
            className="showCardOverlay"
            onClick={() => {
              showClickHandler();
            }}
          ></div>
          <div
            className="playIconContainer"
            onClick={() => {
              showClickHandler();
            }}
          >
            <PlayIcon />
          </div>
          {metaData && data?.show_id && (
            <>
              {watchlistStatus === "added" ? (
                <div
                  className="addToList"
                  onClick={() => removeFromWatchlist(data?.show_id)}
                  title="Remove From Watchlist"
                >
                  <div className="icon">
                    <RemoveListIcon />
                  </div>
                </div>
              ) : (
                <div
                  className="addToList"
                  onClick={() => addToWatchlist(data?.show_id)}
                  title="Add to Watchlist"

                >
                  <div className="icon">
                    <AddListIcon />
                  </div>
                </div>
              )}
              {/* <div className="playTrailor" title="Play Trailor">
                <PlayTrailor/>
              </div> */}
            </>
          )}
        </div>
      </div>

      <div className="metaData">
        <h1 className="title">{data?.show_name || data?.video_title}</h1>
        {metaData ? (
          <>
            <div className="datas">
              {/* <span className="director">{shaheeem}</span> */}
              {/* <span className="country">India</span> */}
              <span className="year">{data?.duration_text}</span>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ShowCard;
