import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const PlanDetails = ({ projectInfo, accountDetails, setCancelSubModal }) => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
    <div className="planDetails">
      <h1 className="heading">
        YOUR {projectInfo?.projectConfig?.config?.TAB_TITLE} SUBSCRIPTION{" "}
      </h1>
      {accountDetails?.cancel_status === false ? (
        <>
          <h2 className="planName">{accountDetails?.subscription_name}</h2>
          <div
            className="buttonContainer"
            
          >
            <button onClick={() => setCancelSubModal(true)}>CANCEL MEMBERSHIP</button>
          </div>
        </>
      ) : (
        <p>Subscription cancelled</p>
      )}
      <Link to="billing-activity">
      <span className="link">BILLING DETAILS</span>
      </Link>
      {accountDetails?.cancel_status === true ? (
          <Link to="/subscription">
             <span className="link">Subscribe</span>
          </Link>
             ) : accountDetails?.change_plan_required === true && (accountDetails?.subscription_type === 3 || accountDetails?.subscription_type === 4) &&
             <>
                {projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ? <Link
                   to="/checkout"
                   state={{ type: "upgrade", subId: accountDetails?.sub_id }}
                    >
                   <span className="link">CHANGE PLAN</span>
                   </Link> :
                   <Link
                    to="/subscription"
                    state={{ type: "upgrade", subId: accountDetails?.sub_id }}
                    >
                    <span className="link">CHANGE PLAN</span>
                    </Link>}
              </>
          }
    </div>
  );
};

export default PlanDetails;
